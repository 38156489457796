$border-radius: 0px;

$cr-blue: #0360a6;
$cr-teal: #01778a;
$cr-pink: #d1368b;
$cr-yellow: #ffd530;
$cr-grey: #1f1f1f;

$font-family-sans-serif: Arial, Helvetica, "Nimbus Sans L", sans-serif;

$theme-colors: (
  "primary":    $cr-teal,
  "secondary":  $cr-pink,
);

$container-padding-x: 0;

$headings-color: $cr-grey;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 700;