@import 'scss/variables';
@import 'bootstrap/scss/bootstrap.scss';

body {
  color: $cr-grey;
}

.search {
  width: 100%;
  background: #01778a;
  box-sizing: border-box;
  padding: 1em 0;
  font-weight: 600;
  color: #ffffff;
  
  :first-letter {
    text-transform: capitalize;
  }
}

.container {
  margin-top: 1em;
  margin-bottom: 1em;
}

.job {
  box-sizing: border-box;
  padding: 1em;
  border-left: 2px solid $cr-pink;
  background: $gray-100;

  h2 a {
    color: $cr-blue;
    font-weight: 700;
    text-decoration: none;
  }
}

h1 {
  text-transform: capitalize;
}

.btn {
  font-weight: 600;
}

strong {
  text-transform: capitalize;
}